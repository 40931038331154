import { sb6, sb7, sb8, vj6, vj7, vj8, vj9, vk4 } from './football'
import { fieldingGlove, fieldingGloveClosed, catchersGear } from './baseball'

export const productTypes: any = {
  SB6: sb6,
  SB7: sb7,
  SB8: sb8,
  VJ6: vj6,
  VJ7: vj7,
  VJ8: vj8,
  VJ9: vj9,
  VK4: vk4,

  //Fielding Glove to support older orders
  'Fielding Glove': fieldingGlove,
  //newly added products
  'Fielding Glove Custom 1': fieldingGlove,
  'Fielding Glove Custom 2': fieldingGlove,
  'Fielding Glove Custom 3': fieldingGlove,
  'Fielding Glove Custom 4': fieldingGlove,
  'Fielding Glove Generic': fieldingGlove,
  'Fielding Glove Shagger': fieldingGlove,
  'Fielding Glove Custom - Closed Back': fieldingGloveClosed,
  'Chest Protector': catchersGear,
  'Leg Guard': catchersGear,
}
