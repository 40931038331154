import { Asset } from 'types'
import {
  asset1,
  asset2,
  asset3,
  asset4,
  asset5,
  asset6,
  swoosh,
  layer3d,
  layerShadow,
  placeholder,
} from './assets'

const assets: Asset[] = [
  { src: asset1, x: 0, y: 0, itemId: 102 },
  { src: asset2, x: 761.7075, y: -0.9513, itemId: 102 },
  { src: asset3, x: 697.0461, y: 767.9424, itemId: 101 },
  { src: asset4, x: 6.2565, y: 6.2859 },
  {
    src: asset5,
    x: 221.3703,
    y: 1230.9888,
    itemId: 109,
    globalComposition: 'source-atop',
  },
  {
    src: asset6,
    x: 163.5183,
    y: 1208.3322,
    itemId: 111,
  },
  {
    src: placeholder,
    x: 0,
    y: 0,
    itemId: 110,
  },
]

const data = {
  assets: assets,
  '3dLayer': [
    { src: layer3d, x: 1.1988, y: -1.797, globalComposition: 'multiply' },
  ],
  shadow: [{ src: layerShadow, x: -206.4765, y: -154.9785 }],
  graphics: [
    {
      src: swoosh,
      x: 305.9283,
      y: 1272.828,
      itemId: 110,
      dynamicAssets: [
        { x: 408, y: 1320, maxWidth: 205, maxHeight: 85, angle: 5 },
        { x: 1574, y: 1320, maxWidth: 205, maxHeight: 85, angle: -5 },
      ],
    },
  ],
}

export default data
