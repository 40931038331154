import { productTypes } from 'data'

export function getProductTypeConfig(product: any, additionalOptions?: any) {
  if (additionalOptions && additionalOptions.back === 'Closed Back') {
    return productTypes['Fielding Glove Custom - Closed Back']
  } else {
    return productTypes[product.name]
  }
}

export function getSelectedColorForProductItem(item: any = {}) {
  return [
    ...(item.options || []),
    ...(item.optionalColors || []),
    ...(item.primaryColors || []),
  ].find((option: any) => !!option.selected)
}
