import React, { useEffect, useState } from 'react'
import { getResizedCanvas } from 'helpers'
import { Icon } from 'components'
import classes from './designer.module.scss'

interface Props {
  list: any[]
  selectedTab: any
  reRender: number
  onSelect: (index: number) => void
}

export default function DesignerNav({
  list,
  selectedTab,
  reRender,
  onSelect,
}: Props) {
  const [navList, setNavList] = useState<any[]>([])
  const [timer] = useState<any>({})

  useEffect(() => {
    timer.thumbnails = setTimeout(
      () => {
        const updatedList: any = []

        const canvasNames: any = {
          'Inside Cuff': 'Left Inside Cuff',
          Tab: 'Top Left Tab',
          'Leg Guard Front': 'Front Right',
          'Leg Guard Side': 'Outside',
        }
        list.forEach((item: any) => {
          const updatedItem: any = { id: item.id, name: item.name }
          if (item.name !== 'Preview All') {
            updatedItem.url = getResizedCanvas(
              document.getElementById(
                (canvasNames[item.name] || item.name)
                  .toLowerCase()
                  .replace(/\s/g, '-'),
              ),
              160,
            ).toDataURL()
          }
          updatedList.push(updatedItem)
        })

        setNavList(updatedList)
      },
      navList.length === 0 ? 4000 : 1000,
    )

    return () => {
      if (timer.thumbnails) {
        clearTimeout(timer.thumbnails)
      }
    }
  }, [reRender])

  const selectTab = (tab: any, index: number) => {
    onSelect(index)
  }

  return (
    <div className={classes.navContainer}>
      <div className={classes.navListContainer}>
        {navList.length > 0 && (
          <div className={classes.navList}>
            {navList.map((item: any, index: number) => {
              return (
                <div
                  key={item.id}
                  className={`${classes.navItem} ${
                    item.id === selectedTab.id ? classes.active : ''
                  }`}
                  onClick={() => selectTab(item, index)}>
                  {item.name === 'Preview All' ? (
                    <Icon name="previewAll" />
                  ) : (
                    <img src={item.url} alt="" />
                  )}
                  <div className={classes.navTitle}>{item.name}</div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
