import AWS from 'aws-sdk'

AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION || 'us-east-1',
  accessKeyId:
    process.env.REACT_APP_AWS_ACCESS_KEY_ID || 'AKIA4J4OMEQQUFW6HVP6',
  secretAccessKey:
    process.env.REACT_APP_AWS_ACCESS_KEY ||
    'M5Jfh+Rzcpz2xfbMJcPCL2jFs+pbsPkmamit3FfH',
})

export const S3Bucket = 'nikegloverteam'
export const S3 = new AWS.S3()
