import { Asset } from 'types'
import {
  asset1,
  asset2,
  asset3,
  asset4,
  asset5,
  asset6,
  swoosh,
  layer3d,
  layerShadow,
  placeholder,
} from './assets'

const assets: Asset[] = [
  { src: asset1, x: 0, y: 0, itemId: 102 },
  { src: asset2, x: 723.7527, y: -0.9042, itemId: 102 },
  { src: asset3, x: 662.3133, y: 729.6768, itemId: 101 },
  { src: asset4, x: 5.9445, y: 5.9724 },
  { src: asset5, x: 155.9283, y: 1161.4845, itemId: 107 },
  { src: asset6, x: 157.8021, y: 1212.1131, itemId: 109 },
  { src: placeholder, x: 0, y: 0, itemId: 108 },
]

const data = {
  assets: assets,
  '3dLayer': [
    { src: layer3d, x: 1.1388, y: -1.7076, globalComposition: 'multiply' },
  ],
  shadow: [{ src: layerShadow, x: -196.188, y: -147.2562 }],
  graphics: [
    {
      src: swoosh,
      x: 326.8599,
      y: 1263.6129,
      itemId: 108,
      dynamicAssets: [
        { x: 402, y: 1302, maxWidth: 170, maxHeight: 56 },
        { x: 1502, y: 1302, maxWidth: 170, maxHeight: 56 },
      ],
    },
  ],
}

export default data
