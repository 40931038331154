import { asset1, asset2 } from './assets'

const assets = [
  { src: asset1, x: 0, y: 0, itemId: 112 },
  { src: asset2, x: 122.6511, y: 711.8517, itemId: 113 },
]

const data = {
  assets: assets,
}

export default data
