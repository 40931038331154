import { asset1, asset2 } from './assets'

const assets = [
  { src: asset1, x: 0, y: 0, itemId: 112 },
  { src: asset2, x: 1053.3747, y: 683.8098, itemId: 113 },
]

const data = {
  assets: assets,
}

export default data
