import { baseURL } from 'providers'
import { S3Bucket, S3 } from './aws'

export function hexToRgb(hex: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

export function rgbToHex(r: number, g: number, b: number) {
  function numberToHex(num: number) {
    var hex = num.toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }

  return '#' + numberToHex(r) + numberToHex(g) + numberToHex(b)
}

export function isLightColor(hex: string) {
  return getLightColorPercentage(hex) > 60
}

export function getLightColorPercentage(hex: string) {
  let rgb = hexToRgb(hex) || { r: 255, g: 255, b: 255 }

  return (rgb.r + rgb.g + rgb.b) / 7.65
}

export function darkenColor(hex: string, percentage: number = 20) {
  let rgb = hexToRgb(hex) || { r: 255, g: 255, b: 255 }

  rgb.r = Math.ceil(rgb.r - ((275 - rgb.r) * percentage) / 255)
  rgb.g = Math.ceil(rgb.g - ((275 - rgb.g) * percentage) / 255)
  rgb.b = Math.ceil(rgb.b - ((275 - rgb.b) * percentage) / 255)

  if (rgb.r < 0) {
    rgb.r = 0
  }
  if (rgb.g < 0) {
    rgb.g = 0
  }
  if (rgb.b < 0) {
    rgb.b = 0
  }

  if (rgb.r > 255) {
    rgb.r = 255
  }
  if (rgb.g > 255) {
    rgb.g = 255
  }
  if (rgb.b > 255) {
    rgb.b = 255
  }

  return rgbToHex(rgb.r, rgb.g, rgb.b)
}

export function is2dLengthEqual(sourceObj: any[], destinationObj: any[]) {
  const keys: any[] = Object.keys(sourceObj)

  for (let i = 0; i < keys.length; i++) {
    let a1 = sourceObj[keys[i]],
      a2 = destinationObj[keys[i]]

    if (
      !a1 ||
      !a2 ||
      Object.values(a1).filter((obj: any) => !!obj.src).length !==
        Object.keys(a2).length
    ) {
      return false
    }
  }

  return true
}

export function loadImage(src: string, canvas?: boolean) {
  return new Promise((resolve: any, reject: any) => {
    const img = new Image()
    img.onload = function () {
      resolve(img)
    }
    img.onerror = reject
    if (canvas) {
      img.src = `${baseURL}getImage?src=${src}?id=${Date.now()}`
      // img.crossOrigin = 'anonymous'
      // img.src = src
    } else {
      img.src = src
    }
  })
}

export function generateThumbnail(
  orderId: string,
  canvasId: string,
  index: number,
) {
  return new Promise((resolve, reject) =>
    getResizedCanvas(document.getElementById(canvasId), 600).toBlob(
      (blob: any) => {
        const params = {
          Bucket: S3Bucket,
          Key: `player/${orderId}-thumbnail-${Date.now()}.png`,
          Body: blob,
          ContentEncoding: 'base64',
          ContentType: `image/*`,
        }

        S3.upload(params)
          .promise()
          .then((res: any) => resolve(res))
      },
    ),
  )
}

export function getResizedCanvas(
  canvas: any,
  maxSize: number,
  imageWithBorder?: boolean,
) {
  const resizedCanvas = document.createElement('canvas')
  const ctx: any = resizedCanvas.getContext('2d')
  var canvasWidth = canvas.width
  var canvasHeight = canvas.height
  const ratio = maxSize / Math.max(canvas.width, canvasHeight)
  const newCanvasWidth = canvasWidth * ratio
  const newCanvasHeight = canvasHeight * ratio
  let x = 0,
    y = 0

  if (imageWithBorder) {
    resizedCanvas.width = maxSize
    resizedCanvas.height = maxSize
    x = (maxSize - newCanvasWidth) / 2
    y = (maxSize - newCanvasHeight) / 2
  } else {
    resizedCanvas.width = newCanvasWidth
    resizedCanvas.height = newCanvasHeight
  }
  ctx.drawImage(
    canvas,
    0,
    0,
    canvasWidth,
    canvasHeight,
    x,
    y,
    newCanvasWidth,
    newCanvasHeight,
  )

  return resizedCanvas
}

export const getImageScaleRatio = (
  image: any,
  maxWidth: number,
  maxHeight: number,
) => {
  const ratio = Math.min(maxWidth / image.width, maxHeight / image.height)

  return ratio
}
