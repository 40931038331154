import top from './top'
import palm from './palm'
import leftCuff from './left-cuff'
import rightCuff from './right-cuff'
import topLeftTab from './top-left-tab'
import topRightTab from './top-right-tab'
import bottomLeftTab from './bottom-left-tab'
import bottomRightTab from './bottom-right-tab'
import dieCast from './die-cast'
import smallDieCast from './die-cast-small'

const views = [
  {
    id: 'top',
    name: 'Back of Hand',
    iconName: 'topOfHand',
    productAssets: top,
    productItems: [],
    reRender: 0,
    thumbnail: true,
  },
  {
    id: 'palm',
    name: 'Palm',
    iconName: 'palmOfHand',
    productAssets: palm,
    productItems: [],
    reRender: 0,
    thumbnail: true,
  },
  {
    id: 'leftCuff',
    name: 'Left Inside Cuff',
    iconName: 'leftInsideCuff',
    styleName: 'INSIDE CUFF LEFT',
    productAssets: leftCuff,
    productItems: [],
    reRender: 0,
  },
  {
    id: 'rightCuff',
    name: 'Right Inside Cuff',
    iconName: 'rightInsideCuff',
    styleName: 'INSIDE CUFF RIGHT',
    productAssets: rightCuff,
    productItems: [],
    reRender: 0,
  },
  // {
  //   id: 'smallDieCast',
  //   name: 'Small Die Cast',
  //   iconName: 'diecast',
  //   productAssets: smallDieCast,
  //   productItems: [],
  //   reRender: 0,
  // },
  // {
  //   id: 'dieCast',
  //   name: 'Die Cast',
  //   iconName: 'diecast',
  //   productAssets: dieCast,
  //   productItems: [],
  //   reRender: 0,
  // },
  {
    id: 'topLeftTab',
    name: 'Top Left Tab',
    iconName: 'tabs',
    productAssets: topLeftTab,
    productItems: [],
    reRender: 0,
  },
  {
    id: 'topRightTab',
    name: 'Top Right Tab',
    iconName: 'tabs',
    productAssets: topRightTab,
    productItems: [],
    reRender: 0,
  },
  {
    id: 'bottomLeftTab',
    name: 'Bottom Left Tab',
    iconName: 'tabs',
    productAssets: bottomLeftTab,
    productItems: [],
    reRender: 0,
  },
  {
    id: 'bottomRightTab',
    name: 'Bottom Right Tab',
    iconName: 'tabs',
    productAssets: bottomRightTab,
    productItems: [],
    reRender: 0,
  },
]

const tabs = [
  {
    id: 1,
    name: 'Back of Hand',
    prefix: '',
    iconName: 'topOfHand',
    views: [views[0]],
  },
  {
    id: 2,
    name: 'Palm',
    prefix: '',
    iconName: 'palmOfHand',
    views: [views[1]],
  },
  {
    id: 3,
    name: 'Inside Cuff',
    prefix: '',
    iconName: 'leftInsideCuff',
    views: [views[2], views[3]],
    styleProps: [
      {
        maxWidth: '500px',
        width: '50%',
        height: '50%',
        margin: '0 auto',
      },
      {
        maxWidth: '500px',
        width: '50%',
        height: '50%',
        margin: '0 auto',
      },
    ],
  },
  // {
  //   id: 4,
  //   name: 'Die Cast',
  //   prefix: '',
  //   iconName: 'diecast',
  //   views: [views[4], views[5]],
  //   styleProps: [{}, { display: 'none' }],
  // },
  {
    id: 6,
    name: 'Tab',
    prefix: '',
    iconName: 'tabs',
    views: [views[4], views[5], views[6], views[7]],
    styleProps: [
      {
        width: '50%',
        height: '50%',
      },
      {
        width: '50%',
        height: '50%',
      },
      {
        width: '50%',
        height: '50%',
      },
      {
        width: '50%',
        height: '50%',
      },
    ],
    aspectRatio: {
      x: 2,
      y: 1,
    },
  },
]

const previewAllTabs = [
  {
    id: 0,
    name: 'Preview All',
    prefix: 'preview',
    iconName: '',
    views: [views[0], views[1], views[2], views[3]],
    styleProps: [
      { width: '40%', height: '70%' },
      { width: '60%', height: '70%' },
      { width: '40%', height: '30%' },
      { width: '40%', height: '30%', marginLeft: '10%' },
    ],
    aspectRatio: {
      x: 6,
      y: 5,
    },
  },
  ...tabs,
]

const config = {
  views,
  tabs,
  previewAllTabs,
  brands: [
    {
      name: 'Nike',
      value: 'Nike',
      icon: 'nike',
      patterns: [{ name: 'Solid', value: 'Solid' }],
    },
    {
      name: 'Jordan',
      value: 'Jordan',
      icon: 'jordan',
      patterns: [{ name: 'Solid', value: 'Solid' }],
    },
  ],
  palmMaterials: {
    football: [
      {
        name: 'Leather',
        value: 'Leather',
      },
      {
        name: 'Hydragrip',
        value: 'Hydragrip',
      },
      {
        name: 'Magnagrip Silicone',
        value: 'Magnagrip Silicone',
        patterns: {
          Nike: [
            { name: 'Solid', value: 'Solid' },
            { name: 'Glitter', value: 'Glitter' },
            { name: 'Paint Swirl', value: 'Paint Swirl' },
          ],
          Jordan: [
            { name: 'Solid', value: 'Solid' },
            { name: 'Glitter', value: 'Glitter' },
            { name: 'Paint Swirl', value: 'Paint Swirl' },
            { name: 'Jordan 1 Sole', value: 'Jordan 1 Sole' },
          ],
        },
        logoPlacements: [
          { name: 'Right Small Cuff', value: 'Right Small Cuff' },
          { name: 'Left Small Cuff', value: 'Left Small Cuff' },
          { name: 'Right Middle Palm', value: 'Right Middle Palm' },
          { name: 'Left Middle Palm', value: 'Left Middle Palm' },
          { name: 'LockUp', value: 'LockUp' },
        ],
      },
    ],
    'football-ncaa': [
      {
        name: 'Magnigrip+',
        value: 'Magnigrip+',
        patterns: {
          Nike: [{ name: 'Solid', value: 'Solid' }],
        },
      },
    ],
  },
  logoPositions: {
    'Small Cuff': {
      right: {
        x: 135,
        y: 885,
        angle: 60,
      },
      left: {
        x: 1320,
        y: 890,
        angle: -55,
      },
    },
    'Middle Palm': {
      right: {
        x: 400,
        y: 675,
        angle: 45,
      },
      left: {
        x: 1060,
        y: 680,
        angle: -45,
      },
    },
    LockUp: {
      x: 725,
      y: 600,
    },
  },
  // dieCastLogoPositions: {
  //   'Small Cuff': {
  //     right: {
  //       x: 225,
  //       y: 535,
  //       angle: 45,
  //     },
  //     left: {
  //       x: 1064,
  //       y: 535,
  //       angle: -45,
  //     },
  //   },
  //   'Middle Palm': {
  //     right: {
  //       x: 280,
  //       y: 415,
  //       angle: 45,
  //     },
  //     left: {
  //       x: 1010,
  //       y: 415,
  //       angle: -45,
  //     },
  //   },
  // },
  customPattern: {
    'Back of Hand': {
      x: 713,
      y: 1651,
      maxWidth: 300,
      maxHeight: 90,
      angle: -1,
    },
    'Top Left Tab': {
      x: 264,
      y: 85,
      maxWidth: 345,
      maxHeight: 104,
      angle: 1,
    },
    'Top Right Tab': {
      x: 311,
      y: 85,
      maxWidth: 345,
      maxHeight: 104,
      angle: -1,
    },
  },
}

const data = {
  config,
  top,
  palm,
  leftCuff,
  rightCuff,
  //dieCast,
  //smallDieCast,
  topLeftTab,
  topRightTab,
  bottomLeftTab,
  bottomRightTab,
}

export default data
