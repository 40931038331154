import { asset1, asset2 } from './assets'

const assets = [
  { src: asset1, x: 0, y: 0, itemId: 112 },
  { src: asset2, x: 150.4554, y: 153.057, itemId: 113, blendMode: 'no-blend' },
]

const data = {
  assets: assets,
}

export default data
