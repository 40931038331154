import { asset1, placeholder } from './assets'

const assets = [
  { src: asset1, x: 0, y: 0, itemId: 110 },
  { src: placeholder, x: 0, y: 0, itemId: 111 },
]

const data = {
  assets: assets,
}

export default data
