import {
  asset1,
  asset2,
  asset3,
  asset4,
  asset5,
  asset6,
  asset7,
  asset8,
  asset9,
  asset10,
  asset11,
  asset12,
  asset13,
  asset14,
  asset15,
  swoosh,
  swooshOutline,
  layer3d,
  layerShadow,
} from './assets'

const assets = [
  { src: asset1, x: 0, y: 0, itemId: 101 },
  { src: asset2, x: -4.8993, y: 0 },
  { src: asset3, x: 328.9572, y: 100.6221, itemId: 104 },
  { src: asset4, x: -3.1521, y: 740.1489, itemId: 108 },
  { src: asset5, x: -4.7193, y: 76.8324, itemId: 102 },
  { src: asset6, x: 784.5297, y: 826.0458 },
  { src: asset7, x: 784.638, y: 826.1727, itemId: 102 },
  { src: asset8, x: 384.2193, y: 1329.537, itemId: 109 },
  { src: asset9, x: 485.3901, y: 155.3685, itemId: 107 },
  { src: asset10, x: 4.6857, y: 77.6196, itemId: 106 },
  { src: asset11, x: 137.4606, y: 446.3232 },
  { src: asset12, x: 418.7709, y: 1382.5647, itemId: 111 },
  { src: asset13, x: 429.8709, y: 1412.6517, itemId: 112 },
  { src: asset14, x: 456.8931, y: 1420.8318, itemId: 112 },
  { src: asset15, x: 465.7656, y: 1607.5053, itemId: 113 },
  { src: swooshOutline, x: 526.1163, y: 904.5255, itemId: 101 },
  { src: swoosh, x: 535.0344, y: 913.4361, itemId: 103 },
]

const data = {
  assets: assets,
  '3dLayer': [
    { src: layer3d, x: -5.8203, y: -8.8704, globalComposition: 'multiply' },
  ],
  shadow: [{ src: layerShadow, x: 15.6132, y: -54.7008 }],
}

export default data
